import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import VideoModal from "../../components/videoModal";

import heroVideo from "../../images/hero_video.mp4";
import videoPoster from "../../images/collections/passage-through-time/campaign/banner.jpg";

import withLocation from "../../components/withLocation";

class PassageThroughTimeCollection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "tab1",
      videoModalOpen: false,
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.toggleVideoModal = this.toggleVideoModal.bind(this);
  }

  componentDidMount() {
    if (this.props.search.page === "runway") {
      this.setState({ activeTab: "tab3" });
    }
  }

  toggleTab(tab) {
    this.setState({ activeTab: tab });
  }

  toggleVideoModal(e) {
    this.setState((prevState) => ({
      videoModalOpen: !prevState.videoModalOpen,
    }));
  }

  render() {
    return (
      <Layout>
        <SEO title="Passage Through Time Collection" />

        <section className="hero-banner">
          <div className="columns">
            <div className="column">
              <Img fluid={this.props.data.banner.childImageSharp.fluid} />
              <h1 className="hero-banner__title">
                Passage Through Time Collection
              </h1>
              <h2 className="hero-banner__subtitle">
                “Covid 19 pandemic swept the world in one day and the same
                happened to the feeling of time. My distorted sense of time this
                year, left a strong mark on the collection as well. It can be
                observed in deformed, twisted silhouettes, spontaneous curves
                and unusual human figure shapes and proportions, made to confuse
                the perception and common sense.”
                <br />
                <br />
                ‘Passage Through Time’ is an artistic journey of time–traveling,
                from 16th – mid 17th century men’s clothes cut and construction,
                to the archetypal Julia Cameron’s Victorian figures,
                Hammershoi’s solitary atmosphere of peculiarly closed ‘home’
                without the least sense of the world outside, and Traditional
                Dutch Costumes from beginning of the twentieth century.
                <br />
                <br />
                The film brings out a silent, static world abandoned by
                humanity. The absence of life is even more pronounced in this
                slightly dense, oppressive and almost ghostly atmosphere. The
                presence of the human figure here has a sense of homelessness,
                because of his fleeting presence in the eternity of time and
                space.
              </h2>
            </div>
          </div>
        </section>

        <video
          autoPlay
          playsInline
          muted
          loop
          id="myVideo"
          className="homepage-video"
          poster={videoPoster}
        >
          <source src={heroVideo} type="video/mp4" />
        </video>

        <button
          onClick={this.toggleVideoModal}
          className="watch-video-btn button is-dark"
        >
          Watch Video
        </button>
        <VideoModal
          videoId="vBpByXTerYY"
          videoModalOpen={this.state.videoModalOpen}
          toggleVideoModal={this.toggleVideoModal}
        />

        <div className="tabs is-centered">
          <ul>
            <li
              className={this.state.activeTab === "tab1" ? " is-active " : " "}
              onClick={() => this.toggleTab("tab1")}
            >
              <a>Campaign</a>
            </li>
            <li
              className={this.state.activeTab === "tab2" ? " is-active " : " "}
              onClick={() => this.toggleTab("tab2")}
            >
              <a>Look book</a>
            </li>
          </ul>
        </div>

        <section className="collection-wrapper">
          <div id="tab-content">
            <div
              className={this.state.activeTab === "tab1" ? " is-active " : " "}
              data-content="1"
            >
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.P1963.childImageSharp.fluid} />
                  <Img
                    fluid={this.props.data.P2024.childImageSharp.fluid}
                    className="next-image"
                  />
                  <Img
                    fluid={this.props.data.P2030.childImageSharp.fluid}
                    className="next-image"
                  />
                  <Img
                    fluid={this.props.data.P2042.childImageSharp.fluid}
                    className="next-image"
                  />
                  <Img
                    fluid={this.props.data.P2054.childImageSharp.fluid}
                    className="next-image"
                  />
                  <Img
                    fluid={this.props.data.P2077.childImageSharp.fluid}
                    className="next-image"
                  />
                  <Img
                    fluid={this.props.data.P2089.childImageSharp.fluid}
                    className="next-image"
                  />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.P2090.childImageSharp.fluid} />
                  <Img
                    fluid={this.props.data.P2129.childImageSharp.fluid}
                    className="next-image"
                  />
                  <Img
                    fluid={this.props.data.P2270.childImageSharp.fluid}
                    className="next-image"
                  />
                  <Img
                    fluid={this.props.data.P2328.childImageSharp.fluid}
                    className="next-image"
                  />
                  <Img
                    fluid={this.props.data.P2400.childImageSharp.fluid}
                    className="next-image"
                  />
                  <Img
                    fluid={this.props.data.P2471.childImageSharp.fluid}
                    className="next-image"
                  />
                  <Img
                    fluid={this.props.data.P2509.childImageSharp.fluid}
                    className="next-image"
                  />
                </div>
              </div>
            </div>

            <div
              className={this.state.activeTab === "tab2" ? " is-active " : " "}
              data-content="2"
            >
              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_0004.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0055.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0017.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_0011.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0032.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0007.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_0027.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0073.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0082.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_0064.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0092.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0050.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_0041.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0020.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0066.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_0081.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0005.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0059.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_0014.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0039.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0035.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_0083.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0101.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0069.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_0098.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0155.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0126.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_0130.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0162.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0176.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_0214.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0248.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0225.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_0289.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0396.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0375.childImageSharp.fluid} />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Img fluid={this.props.data.IMG_0304.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={this.props.data.IMG_0361.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

PassageThroughTimeCollection.propTypes = {};

export default withLocation(PassageThroughTimeCollection);

export const fluidImagePassageThroughTimeBanner = graphql`
  fragment fluidImagePassageThroughTimeBanner on File {
    childImageSharp {
      fluid(maxWidth: 2560) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const fluidImagePassageThroughTime = graphql`
  fragment fluidImagePassageThroughTime on File {
    childImageSharp {
      fluid(maxWidth: 1280) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    banner: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/banner.jpg"
      }
    ) {
      ...fluidImagePassageThroughTimeBanner
    }
    P1963: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/P1963.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    P2024: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/P2024.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    P2030: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/P2030.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    P2042: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/P2042.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    P2054: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/P2054.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    P2077: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/P2077.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    P2089: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/P2089.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    P2090: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/P2090.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    P2129: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/P2129.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    P2270: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/P2270.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    P2328: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/P2328.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    P2400: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/P2400.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    P2471: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/P2471.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    P2509: file(
      relativePath: {
        eq: "collections/passage-through-time/campaign/P2509.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0004: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0004.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0017: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0017.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0039: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0039.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0064: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0064.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0082: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0082.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0126: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0126.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0214: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0214.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0361: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0361.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0005: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0005.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0020: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0020.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0041: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0041.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0066: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0066.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0083: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0083.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0130: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0130.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0225: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0225.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0375: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0375.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0007: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0007.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0027: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0027.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0050: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0050.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0069: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0069.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0092: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0092.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0155: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0155.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0248: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0248.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0396: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0396.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0011: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0011.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0032: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0032.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0055: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0055.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0073: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0073.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0098: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0098.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0162: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0162.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0289: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0289.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0014: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0014.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0035: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0035.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0059: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0059.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0081: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0081.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0101: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0101.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0176: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0176.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
    IMG_0304: file(
      relativePath: {
        eq: "collections/passage-through-time/lookbook/IMG_0304.jpg"
      }
    ) {
      ...fluidImageAbyss
    }
  }
`;
